import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import BaseTabs from '@/components/base/baseTabs';
import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import Heatmap from '@/pages/contract/heatmap';
import CoinList from '@/pages/public/market/components/CoinList';
import CollectList from '@/pages/public/market/components/CollectList';
import ContractList from '@/pages/public/market/components/ContractList';
import FinanceList from '@/pages/public/market/components/FinanceList';
import ProjectSetting from '@/setting/projectSetting';
import { parseQueryString } from '@/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StockList from './components/stock/StockList';
import { Divider } from '@nextui-org/react';

/**
 * 股票市场
 */
export default BaseWithErrorBoundary(({}: any) => {
  const { t } = useTranslation();
  //币种列表配置
  const tabOptions = [
    { text: t('自选'), value: GetCoinListTypeEnum.COLLECT },
    { text: t('现货'), value: GetCoinListTypeEnum.COIN },
    { text: t('合约'), value: GetCoinListTypeEnum.CONTRACT },
    ProjectSetting.isShowStock
      ? { text: t('股票'), value: GetCoinListTypeEnum.STOCK }
      : null,
    { text: t('IEO'), value: GetCoinListTypeEnum.IEO },
    { text: t('热力图'), value: GetCoinListTypeEnum.HEATMAP },
  ].filter((i) => !!i);

  //默认选中 的
  const [tabSelected, setTabSelected] = useState(GetCoinListTypeEnum.COIN);
  useEffect(() => {
    const search = parseQueryString(window.location.search);
    if (search.type) {
      setTabSelected(search.type);
    }
  }, [location.pathname]);

  // 回到页面刷新
  const pageKey = usePageRefreshOnVisible();
  // 用于做外部加载
  const [loadingDisplay, setLoadingDisplay] = useState(false);
  return (
    <div className="min-h-[40vh]" key={pageKey}>
      <div className="flex flex-wrap justify-between items-center w-full ">
        <div className="px-[10px] flex justify-between items-center w-full ">
          <BaseTabs
            value={tabSelected}
            onChange={setTabSelected}
            options={tabOptions}
            tabsPropsProps={{
              classNames: {
                tab: '!text-[16px] !px-1 !h-[40px]',
                tabList: '!pb-0',
              },
            }}
          />

          {/* 加载 */}
          {loadingDisplay && (
            <div className="flex items-center justify-center w-fit h-full">
              <SvgSpinnersBarsRotateFade />
            </div>
          )}
        </div>
        <Divider className="!bg-backgroundAuxiliaryColor/80" />
      </div>
      <div className="px-4">
        {/*收藏展示*/}
        {GetCoinListTypeEnum.COLLECT === tabSelected && (
          <CollectList setLoadingDisplay={setLoadingDisplay} />
        )}

        {/*币币*/}
        {GetCoinListTypeEnum.COIN === tabSelected && (
          <CoinList setLoadingDisplay={setLoadingDisplay} />
        )}

        {/*合约*/}
        {GetCoinListTypeEnum.CONTRACT === tabSelected && (
          <ContractList setLoadingDisplay={setLoadingDisplay} />
        )}

        {/*股票*/}
        {GetCoinListTypeEnum.STOCK === tabSelected && (
          <StockList setLoadingDisplay={setLoadingDisplay} />
        )}

        {/*Ieo*/}
        {GetCoinListTypeEnum.IEO === tabSelected && (
          <FinanceList setLoadingDisplay={setLoadingDisplay} />
        )}

        {/*热力图*/}
        {GetCoinListTypeEnum.HEATMAP === tabSelected && (
          <Heatmap setLoadingDisplay={setLoadingDisplay} />
        )}
      </div>
    </div>
  );
});
