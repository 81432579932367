import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseSift from '@/components/base/baseSift';
import SortColumn from '@/components/features/featuresMarketList/SortColumn';
import {
  ContractListTypeEnum,
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import CoinCard from '@/pages/public/market/components/CoinCard';
import ContractCard from '@/pages/public/market/components/ContractCard';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import { useModel } from '@@/exports';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  fuzzy,
  setLoadingDisplay,
}: {
  fuzzy?: string;
  setLoadingDisplay?: (val: boolean) => void;
}) => {
  // 基础定义
  const { t } = useTranslation();
  const { sendMsg, isSub } = useModel('socket');
  const { sortState, setSortState, setIsHomeSort } = useModel('system');
  //收藏配置
  const [collectOptions] = useState([
    { text: t('合约'), value: GetCoinListTypeEnum.CONTRACT },
    { text: t('现货'), value: GetCoinListTypeEnum.COIN },
  ]);
  const [collectSelected, setCollectSelected] = useState(collectOptions[0]);

  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 100,
  });
  // 自动监听参数自动请求
  const params = useMemo(() => {
    return {
      ...pageParams,
      type: GetCoinListTypeEnum.COLLECT,
      contractType: ContractListTypeEnum.CRYPTOCURRENCY,
      isTripartite: collectSelected?.value,
      ...sortState,
    };
  }, [pageParams, sortState, collectSelected]);

  // 获取列表
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: getTradingSymbolsApi,
      params,
      setParams: setPageParams,
    });

  useEffect(() => {
    if (list?.length) {
      // 订阅当前 加载的股票
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          list.map((i: any) => i.symbol),
        )}}`,
      );
    }
  }, [list, isSub]);

  // 排序
  const onSort = (id: number, sortOrder: 'asc' | 'desc' | null) => {
    let newSortState = { ...sortState };
    // 重置其他字段的排序为 0，并只更新当前点击的字段
    switch (id) {
      case 2: // 价格
        newSortState = {
          priceSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownSort: 0, // 保留其他字段的排序状态
          upDownRangSort: 0,
        };
        break;

      case 3: // 涨跌幅
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: 0,
          upDownRangSort:
            sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
        };
        break;

      case 1: // 名称
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownRangSort: 0,
        };
        break;
      default:
        break;
    }
    // 更新排序状态
    setSortState(newSortState);
  };

  // 收藏前端自适应厚度按，做搜素
  const filterList: any = useMemo(() => {
    if (fuzzy && list?.length) {
      return list.filter(
        (i: any) =>
          i?.alias?.toLocaleUpperCase?.()?.includes?.(fuzzy.toLocaleUpperCase()) ||
          i?.name?.toLocaleUpperCase?.()?.includes?.(fuzzy.toLocaleUpperCase()),
      );
    }

    return list;
  }, [fuzzy, list]);

  useEffect(() => {
    if (setLoadingDisplay) {
      setLoadingDisplay?.(loading);
    }
  }, [loading]);

  return (
    <div>
      <div className=" justify-between  mt-3">
        <div className="flex justify-between  mt-3">
          <div className="flex">
            <BaseSift
              options={collectOptions as any}
              onChange={setCollectSelected as any}
              current={collectSelected}
              title={t('类型')}
            />
          </div>
        </div>
        <div className="text-iconFontColor my-2 flex justify-between auxiliaryText text-xs pr-[5px]">
          <div className="flex-2">
            <SortColumn
              id={1}
              label={t('名称')}
              onSort={onSort}
              active={sortState.upDownSort !== null} // 如果不为 0，则表示激活状态
              sortOrder={
                sortState.upDownSort === 1
                  ? 'asc'
                  : sortState.upDownSort === -1
                  ? 'desc'
                  : null
              } // 根据状态显示升序、降序或未排序
            />
          </div>

          <div className="flex-1 pr-2">
            <SortColumn
              id={2}
              onSort={onSort}
              label={t('价格')}
              className="justify-end"
              active={sortState.priceSort !== null}
              sortOrder={
                sortState.priceSort === 1
                  ? 'asc'
                  : sortState.priceSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
          <div className="flex-1 !min-w-fit">
            <SortColumn
              id={3}
              onSort={onSort}
              label={t('涨幅比')}
              className="justify-end"
              active={sortState.upDownRangSort !== null}
              sortOrder={
                sortState.upDownRangSort === 1
                  ? 'asc'
                  : sortState.upDownRangSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
        </div>
        <div className="min-h-[40vh]">
          <PullToRefresh
            loadMore={loadMore}
            total={total}
            list={filterList}
            error={error}
            loading={loading}
            loadingDisplay={false}
            {...resetPageProps}
          >
            {filterList?.map((item: any) => {
              if (collectSelected.value === GetCoinListTypeEnum.CONTRACT) {
                return <ContractCard key={item?.id} isCollect item={item} />;
              }
              return <CoinCard key={item?.id} isCollect item={item} />;
            })}
          </PullToRefresh>
        </div>
      </div>
    </div>
  );
};
